<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div>
          <b-row class="no-gutters">
            <b-col class="text-left">
              <h1 class="mr-sm-4 header-tablepage">
                TRANSACTION DETAIL REF: {{ transactionDetail.invoice_no }}
              </h1>
            </b-col>
            <b-col class="text-right">
              <button
                type="button"
                class="btn button btn-mobile mx-2 btn-width"
                @click.prevent="deleteTransaction"
                v-if="showBtnDelete"
              >
                <span class="d-none d-sm-block">Delete</span>
              </button>
              <button
                type="button"
                class="btn button btn-mobile btn-width"
                @click.prevent="voidTransaction"
                v-if="showBtnVoid"
              >
                <span class="d-none d-sm-block"> Void/Return </span>
              </button>
            </b-col>
          </b-row>
        </div>
        <div>
          <TransactionDetailSection
            :detail="transactionDetail"
            @modalEditNote="modalNoteEdit"
            @modalBranch="modalBranch"
            @modalCenterPoint="modalCenterPoint"
            :centerPoint="centerPoint"
          />
        </div>
        <div>
          <TransactionProductSection
            :items="transactionDetail.product_list"
            :fields="fields"
          />
        </div>
      </div>
    </div>
    <div class="footer-action">
      <b-col class="text-left">
        <b-button @click.prevent="beforePage" class="btn-cancel btn-width"
          >Back</b-button
        >
      </b-col>
    </div>
    <ModalEditNote
      ref="modalEditNote"
      :form="form"
      :v="$v"
      @updateNote="updateNote"
      :loadingNote="loadingNote"
    />
    <DeleteTransactionDetail
      ref="modalDeleteTransaction"
      @confirmDeleteTransaction="confirmDeleteTransaction"
    />

    <VoidTransactionDetail
      :disableBtnVoid="disableBtnVoid"
      ref="modalVoidTransaction"
      :form="formVoid"
      :v="$v"
      :invoiceLength="transactionDetail.invoice_length"
      @confirmVoidTransaction="confirmVoidTransaction"
    />
    <ModalBranchList
      ref="modalBranchPoint"
      :fields="fieldsBranch"
      :items="itemsBranchPoint"
    />
    <ModalBranchList
      ref="modalBranchPoint"
      :fields="fieldsBranch"
      :items="itemsBranchPoint"
    />
    <!-- <ModalCenterPoint
      ref="modalCenterPoint"
      :fields="fieldsCenterPoint"
      :items="centerPoint"
    /> -->
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import TransactionDetailSection from "@/components/report/transaction/detail/TransactionDetailSection";
import TransactionProductSection from "@/components/report/transaction/detail/TransactionProductSection";
import ModalEditNote from "@/components/report/transaction/detail/ModalEditNote";
import { required, requiredIf } from "vuelidate/lib/validators";
import ModalBranchList from "@/components/report/transaction/detail/ModalBranchList";
import VoidTransactionDetail from "@/components/report/transaction/detail/VoidTransactionDetail";
import DeleteTransactionDetail from "@/components/report/transaction/detail/DeleteTransactionDetail";
export default {
  components: {
    OtherLoading,
    TransactionDetailSection,
    TransactionProductSection,
    ModalEditNote,
    ModalBranchList,
    VoidTransactionDetail,
    DeleteTransactionDetail,
  },
  validations: {
    form: {
      note: { required },
    },
    formVoid: {
      invoice_no: {
        required: requiredIf(function () {
          return this.formVoid.is_fullpos == 0;
        }),
      },
    },
  },
  data() {
    return {
      isLoading: true,
      id: this.$route.params.id,
      transactionDetail: {},
      fields: [
        {
          label: "SKU / Description / Promotion",
          key: "article_no",
        },
        {
          label: "Quantity",
          key: "quantity",
        },
        {
          label: "Price (Baht)",
          key: "price",
        },
        {
          label: "Promotional Price (Baht)",
          key: "promotion_price",
        },
        {
          label: "Discount (Baht)",
          key: "sum_discount_price",
        },
        {
          label: "Net Price (Baht)",
          key: "after_discount_price",
        },
      ],
      form: {
        transaction_id: 0,
        note: "",
      },
      loadingNote: false,
      fieldsBranch: [
        {
          key: "branch_name",
          label: "Branch",
        },
        {
          key: "branch_point",
          label: "Point",
        },
      ],
      itemsBranchPoint: [],
      formVoid: {
        transaction_id: "",
        sale_user_guid: null,
        invoice_length: 0,
        is_fullpos: 0,
        invoice_no: null,
        note: "",
        is_return_inventory: 0,
      },
      formDelete: {
        transaction_id: "",
        sale_user_guid: null,
        branch_id: null,
        is_return_inventory: 0,
      },
      disableBtnVoid: false,
      showBtnDelete: true,
      showBtnVoid: true,
      fieldsCenterPoint: [
        {
          key: "center_point",
          label: "Point",
        },
      ],
      centerPoint: 0,
    };
  },
  async created() {
    document.body.style.setProperty("--margin-bottom", "4rem");
    await this.getTransactionById();
    await this.getNote();
    await this.getBranchPoint();
    await this.getPointByUser();
  },
  destroyed() {
    document.body.style.setProperty("--margin-bottom", "0px");
  },
  methods: {
    async getTransactionById() {
      await this.$store.dispatch("getReportTransactionById", this.id);
      const data = this.$store.state.report.stateReportTransactionById;
      if (data.result === 1) {
        this.transactionDetail = data.detail;
        if (this.transactionDetail.transaction_ref_id) {
          if (parseInt(this.transactionDetail.transaction_ref_id) !== 0) {
            this.showBtnVoid = false;
          }
        }
        if (
          this.transactionDetail.transaction_ref_id &&
          parseInt(this.transactionDetail.transaction_ref_id) !== 0 &&
          parseInt(this.transactionDetail.status_id) === 1
        ) {
          this.showBtnDelete = false;
        }
        if (parseInt(this.transactionDetail.deleted) === 1) {
          this.showBtnDelete = false;
          this.showBtnVoid = false;
        }
        this.isLoading = false;
      }
    },
    deleteTransaction() {
      this.$refs.modalDeleteTransaction.show();
    },
    voidTransaction() {
      this.formVoid.is_fullpos = this.transactionDetail.is_fullpos;
      this.$refs.modalVoidTransaction.show();
    },
    beforePage() {
      this.$router.push("/report/transaction");
    },
    modalNoteEdit() {
      this.$refs.modalEditNote.show();
    },
    async getNote() {
      this.loadingNote = true;
      await this.$store.dispatch("getNoteReportTransaction", this.id);
      const data = this.$store.state.report.stateNoteReportTransaction;
      if (data.result === 1) {
        this.form.note = data.detail.note;
      }
      this.loadingNote = false;
    },
    async updateNote(val) {
      this.loadingNote = true;
      this.form.transaction_id = this.transactionDetail.transaction_id;
      this.form.note = val.note;
      await this.$store.dispatch("updateNoteReportTransaction", this.form);
      const data = this.$store.state.report.stateUpdateNoteReportTransaction;
      if (data.result === 1) {
        this.$swal("Update Success!", {
          icon: "success",
        }).then(function () {
          window.location.reload();
        });
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    modalCenterPoint() {
      this.$refs.modalCenterPoint.show();
    },
    modalBranch() {
      this.$refs.modalBranchPoint.show();
    },
    async getBranchPoint() {
      await this.$store.dispatch(
        "getTransactionBranchPoint",
        this.transactionDetail.user_guid
      );
      const data = this.$store.state.report.stateTransactionBranchPoint;
      if (data.result === 1) {
        this.itemsBranchPoint = data.detail.listBranchPoint;
      }
    },
    async getPointByUser() {
      await this.$store.dispatch(
        "getPointByReportCustomer",
        this.transactionDetail.user_guid
      );
      const data = this.$store.state.report.stateGetPointByReportCustomer;
      if (data.result === 1) {
        this.centerPoint = data.detail.center_point;
      }
    },
    async confirmVoidTransaction(val) {

      this.disableBtnVoid = true;
      this.formVoid.invoice_no = val.invoice_no;
      this.formVoid.transaction_id = this.transactionDetail.transaction_id;
      this.formVoid.sale_user_guid = this.$cookies.get(
        "back_office_admin_user_guid"
      );
      this.formVoid.note = val.note;
      await this.$store.dispatch("voidTransactionById", this.formVoid);
      const data = this.$store.state.report.stateVoidTransactionById;
      if (data.result === 1) {
        this.$swal(data.message, {
          icon: "success",
        }).then(function () {
          window.location.reload();
        });
      } else {
        this.disableBtnVoid = false;
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    async confirmDeleteTransaction(isReturn) {
      this.formDelete.transaction_id = this.transactionDetail.transaction_id;
      this.formDelete.sale_user_guid = this.$cookies.get(
        "back_office_admin_user_guid"
      );
      this.formDelete.branch_id = this.transactionDetail.branch_id;
      this.formDelete.is_return_inventory = isReturn;
      await this.$store.dispatch("deleteTransactionById", this.formDelete);
      const data = this.$store.state.report.stateDeleteTransactionById;
      if (data.result === 1) {
        this.successAlert().then(function () {
          window.location.reload();
        });
      } else {
        this.errorAlert(data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  padding: 10px;
  background: #fff;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
@media (min-width: 350px) and (max-width: 991px) {
  .footer-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.footer-action {
  margin-left: var(--margin-spacing);
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}
</style>
